<template>
    <label for="patient-listing-filter__customer-request-code">
        <i class="far fa-address-card d-inline" />
        {{ trans['patient.listing.customer-request-code.label'] }}
    </label>
    <div class="input-container bg-white border border-light">
        <input
            id="patient-listing-filter__customer-request-code"
            v-model="filterValue"
            :placeholder="trans['patient.listing.customer-request-code.placeholder']"
            class="form-control"
            @input="handleChange"
        />
    </div>
</template>

<script>
import FiltersMixin from '@/mixins/FiltersMixin'

export default {
    mixins: [FiltersMixin],
}
</script>