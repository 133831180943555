<template>
    <div>{{ format() }}</div>
</template>

<script>
import Column from '@/mixins/Column'
import TherapyColumn from '@/mixins/TherapyColumn'

export default {
    name: 'TherapyInstitutionPatientId',
    mixins: [Column, TherapyColumn],
    methods: {
        format: function () {
            return this.getPatient()
                .map((therapy) => therapy.institutionPatientId)
                .filter((v) => v)
                .join(', ')
        },
    },
}
</script>