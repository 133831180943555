<template>
    <table
        class="table vivisol-table table-list rounded-corners table-borderless table-hover table-sm"
        :class="{
            'vivisol-table-loading': loading,
        }"
    >
        <thead>
            <slot name="header"></slot>
        </thead>

        <tbody v-if="loading">
            <slot name="loading"></slot>
        </tbody>
        <tbody v-else-if="items.length === 0">
            <slot name="empty"></slot>
        </tbody>
        <tbody v-else>
            <template v-for="(item, index) in items" :key="index">
                <slot name="row" :item="item"></slot>
            </template>
        </tbody>
    </table>

    <pagination-widget v-if="!loading" v-model="currentPage" :item-count="itemCount" :items-per-page="itemsPerPage" @update="reload" />
</template>

<script>
import { ajaxRequest, abortAjaxRequest } from '@/services/ajax'
import PaginationWidget from './PaginationWidget.vue'
import Translations from '../mixins/Translations'

export default {
    components: { PaginationWidget },
    mixins: [Translations],
    props: {
        apiUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            items: [],
            itemCount: null,
            currentPage: 1,
            itemsPerPage: null,
            additionalParameters: {},
            instanceId: 'async-table-' + Math.random().toString(36).slice(-8),
        }
    },
    mounted() {
        this.reload()
    },
    methods: {
        updateParameters(additionalParameters, jumpToFirstPage = true) {
            if (jumpToFirstPage) {
                this.currentPage = 1
            }
            this.additionalParameters = additionalParameters
            this.reload()
        },
        reload() {
            // Actively abort any running request
            abortAjaxRequest(this.instanceId)

            this.loading = true

            ajaxRequest(
                this.apiUrl,
                {
                    page: this.currentPage,
                    ...this.additionalParameters,
                },
                'GET',
                this.instanceId,
                (responseBody) => {
                    this.loading = false
                    this.items = responseBody.rows
                    this.itemCount = responseBody.totalRows
                    this.itemsPerPage = responseBody.perPage
                },
                (error) => {
                    this.loading = false
                    this.items = []
                    alert(this.trans['async.loadingError.reloadPage'])
                }
            )
        },
    },
}
</script>