<template>
    <label for="patient-listing-filter__prescriber">
        {{ trans['patient.listing.prescriber-name-select.label'] }}
    </label>
    <div class="input-container bg-white border border-light">
        <select id="patient-listing-filter__prescriber" v-model="filterValue" class="form-control" @change="handleChange">
            <option :value="null">
                {{ trans['patient.listing.prescriber-name-select.placeholder'] }}
            </option>
            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.label }}
            </option>
        </select>
    </div>
</template>

<script>
import FiltersMixin from '@/mixins/FiltersMixin'

export default {
    mixins: [FiltersMixin],
    props: {
        options: {
            type: Array,
            required: true,
        },
    },
}
</script>