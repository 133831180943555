<template>
    <div>{{ format() }}</div>
</template>

<script>
import Column from '@/mixins/Column'
import TherapyColumn from '@/mixins/TherapyColumn'

export default {
    name: 'TherapySatmonVacationMonitor',
    mixins: [Column, TherapyColumn],
    props: {
        vacationDateField: { type: String, required: true },
    },
    methods: {
        format: function () {
            return this.getPatient()
                .map((therapy) => (therapy.mostRecentVacationMonitor ? vivisol.formatDate(therapy.mostRecentVacationMonitor[this.vacationDateField]) : null))
                .filter((v) => v)
                .join(', ')
        },
    },
}
</script>