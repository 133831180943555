<template>
    <label for="patient-listing-filter__institution-team">
        {{ trans['contractor.filter.title'] }}
    </label>
    <div class="input-container bg-white border border-light">
        <select id="patient-listing-filter__institution-team" v-model="filterValue" class="form-control" @change="handleChange">
            <option :value="null">
                {{ trans['contractor.filter.all-all'] }}
            </option>
            <template v-for="(team, label) in nestedChoices">
                <optgroup v-if="typeof team === 'object'" :key="'optgroup' + label" :label="label">
                    <option :value="Object.values(team).join(',')">
                        {{ trans['contractor.filter.all-within-main-contractor'] }}
                    </option>
                    <option v-for="(id, subLabel) in team" :key="id" :value="id">
                        {{ subLabel }}
                    </option>
                </optgroup>
                <option v-else :key="'option' + label" :value="team">
                    {{ label }}
                </option>
            </template>
        </select>
    </div>
</template>

<script>
import FiltersMixin from '@/mixins/FiltersMixin'

export default {
    mixins: [FiltersMixin],
    props: {
        nestedChoices: {
            type: Object,
            required: true,
        },
    },
}
</script>

