<template>
    <label for="patient-listing-filter__last-name">
        <i class="far fa-user d-inline" />
        {{ trans['patient.listing.lastName'] }}
    </label>
    <div class="input-container bg-white border border-light">
        <input v-model="filterValue" :placeholder="trans['patient.listing.lastName']" class="form-control" @input="handleChange" />
    </div>
</template>

<script>
import FiltersMixin from '@/mixins/FiltersMixin'

export default {
    name: 'FilterLastname',
    mixins: [FiltersMixin],
}
</script>