<template>
    <div class="input-container bg-white border border-light px-3">
        <button id="patientListingFilterSubmitButton" type="button" class="btn btn-lg btn-primary" @click="$emit('onClickButton')">
            {{ trans['patient.listing.search'] }}
        </button>
    </div>
</template>

<script>
import Translations from '@/mixins/Translations'

export default {
    mixins: [Translations],
    emits: ['onClickButton'],
}
</script>