<template>
    <DateRangePicker
        v-bind="$attrs"
        v-model:date-from="dateFrom"
        v-model:date-to="dateTo"
        :label="trans['patient.listing.' + field + '.label']"
        :date-from-label="trans['patient.listing.' + field + '.label.from']"
        :date-to-label="trans['patient.listing.' + field + '.label.to']"
        :as-object="false"
        @input="handleChange"
    />
</template>

<script>
import FiltersMixin from '@/mixins/FiltersMixin'
import DateRangePicker from '@/components/common/DateRangePicker'

export default {
    components: {
        DateRangePicker,
    },
    mixins: [FiltersMixin],
    props: {
        field: { type: String, required: true },
    },
    data: function () {
        return {
            dateFrom: null,
            dateTo: null,
        }
    },
    methods: {
        getSelectedFilterValue() {
            return [
                {
                    key: this.filterKey + '_from',
                    value: this.dateFrom,
                },
                {
                    key: this.filterKey + '_to',
                    value: this.dateTo,
                },
            ]
        },
    },
}
</script>