<template>
    <div class="dropdown dropdown-on-hover">
        <button class="btn btn-text btn-block dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            {{ trans['Actions'] }}
        </button>
        <ul class="dropdown-menu dropdown-menu-right m-0 border-0 py-3">
            <span class="dropdown-header pt-0">{{ trans['Choose a new action'] }}</span>
            <action
                v-for="(action, index) in actions"
                :key="index"
                :css-class="action.cssClass"
                :label="action.label"
                :url-template="action.urlTemplate"
                :patient-id="item.patient.id"
            />
        </ul>
    </div>
</template>

<script>
import Column from '@/mixins/Column'
import TherapyColumn from '@/mixins/TherapyColumn'
import Action from './Action'

export default {
    name: 'ActionsComponent',
    components: {
        Action,
    },
    mixins: [Column, TherapyColumn],
    props: {
        actions: {
            type: Object,
            required: true,
        },
    },
}
</script>