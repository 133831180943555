<template>
    <!-- NOTE: This template is duplicated to templates/portal/partials/_pagination.html.twig -->

    <nav v-if="pageCount > 1" aria-label="Pagination">
        <ul class="pagination justify-content-center my-40">
            <!-- First -->
            <li v-if="pageCount > pagesInRange.length" class="page-item" :class="{ disabled: internalValue === 1 }">
                <a
                    class="page-link pagination-first"
                    href="#"
                    :title="trans['pagination.first']"
                    :aria-label="trans['pagination.first']"
                    @click.prevent="() => (internalValue = 1)"
                >
                    <span class="fas fa-chevron-double-left" aria-hidden="true"></span>
                </a>
            </li>

            <!-- Previous -->
            <li class="page-item prev mr-2" :class="{ disabled: internalValue === 1 }">
                <a
                    class="page-link pagination-previous"
                    href="#"
                    :title="trans['pagination.previous']"
                    :aria-label="trans['pagination.previous']"
                    @click.prevent="() => (internalValue -= 1)"
                >
                    <i class="fas fa-caret-left pr-2"></i>
                    <small>{{ trans['pagination.previous'] }}</small>
                </a>
            </li>

            <!-- More pages indication -->
            <li v-if="firstPageInRange > 1" class="page-item disabled">
                <span class="page-link">&mldr;</span>
            </li>

            <template v-for="page in pagesInRange" :key="page">
                <!-- Current -->
                <li v-if="internalValue === page" class="page-item active" aria-current="page">
                    <span class="page-link">
                        {{ page }}
                        <span class="sr-only">({{ trans['pagination.current'] }})</span>
                    </span>
                </li>
                <!-- Page -->
                <li v-else class="page-item">
                    <a class="page-link" href="#" @click.prevent="() => (internalValue = page)">{{ page }}</a>
                </li>
            </template>

            <!-- More pages indication -->
            <li v-if="lastPageInRange < pageCount" class="page-item disabled">
                <span class="page-link">&mldr;</span>
            </li>

            <!-- Next -->
            <li class="page-item next ml-2" :class="{ disabled: internalValue === pageCount }">
                <a class="page-link pagination-next" href="#" :title="trans['pagination.next']" :aria-label="trans['pagination.next']" @click.prevent="() => (internalValue += 1)">
                    <small>{{ trans['pagination.next'] }}</small>
                    <i class="fas fa-caret-right pl-2"></i>
                </a>
            </li>

            <!-- Last -->
            <li v-if="pageCount > pagesInRange.length" class="page-item" :class="{ disabled: internalValue === pageCount }">
                <a
                    class="page-link pagination-last"
                    href="#"
                    :title="trans['pagination.last']"
                    :aria-label="trans['pagination.last']"
                    @click.prevent="() => (internalValue = pageCount)"
                >
                    <span class="fas fa-chevron-double-right" aria-hidden="true"></span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
import Translations from '@/mixins/Translations'

export default {
    mixins: [Translations],
    props: {
        modelValue: {
            type: Number,
            required: true,
        },
        itemCount: {
            type: [Number, null],
            required: true,
        },
        itemsPerPage: {
            type: [Number, null],
            required: true,
        },
        range: {
            type: Number,
            default: 5,
        },
    },
    emits: ['update:modelValue', 'update'],
    computed: {
        internalValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
                this.$emit('update', value)
            },
        },
        pageCount() {
            return this.itemsPerPage ? Math.ceil(this.itemCount / this.itemsPerPage) : null
        },
        firstPageInRange() {
            if (
                this.pageCount <= this.range * 2 || // We can show all pages in range
                this.internalValue <= this.range // Or we're near the start
            ) {
                return 1
            }

            if (this.internalValue >= this.pageCount - this.range) {
                // We can show more pages to the left because there aren't that many pages at the right
                return this.pageCount - 2 * this.range + 1
            }

            return this.internalValue - this.range + 1
        },
        lastPageInRange() {
            return Math.min(this.pageCount, this.firstPageInRange + 2 * this.range - 1)
        },
        pagesInRange() {
            return [...Array(this.lastPageInRange - this.firstPageInRange + 1)].map((_, i) => i + this.firstPageInRange)
        },
    },
}
</script>
