import PatientListing from '@/components/PatientListing'
import ProductLimits from '@/components/ProductLimits'
import ProductLimitsSearch from '@/components/ProductLimitsSearch'
import ProductLimitsEditor from '@/components/ProductLimitsEditor'
import ProductLimitsPackageSearch from '@/components/ProductLimitsPackageSearch'

export default {
    'components/PatientListing': PatientListing,
    'components/ProductLimits': ProductLimits,
    'components/ProductLimitsSearch': ProductLimitsSearch,
    'components/ProductLimitsEditor': ProductLimitsEditor,
    'components/ProductLimitsPackageSearch': ProductLimitsPackageSearch,
}
