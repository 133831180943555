<template>
    <label for="patient-listing-filter__access-to-portal">{{ trans['patient.listing.accessToPortal'] }}</label>
    <div class="input-container bg-white border border-light">
        <select id="patient-listing-filter__access-to-portal" v-model="filterValue" class="form-control" @change="handleChange">
            <option :value="null">
                {{ trans['accessToPortalAll'] }}
            </option>
            <option :value="true">
                {{ trans['accessToPortalYes'] }}
            </option>
            <option :value="false">
                {{ trans['accessToPortalNo'] }}
            </option>
        </select>
    </div>
</template>

<script>
import FiltersMixin from '@/mixins/FiltersMixin'

export default {
    mixins: [FiltersMixin],
}
</script>