var abortControllers = {}

export function ajaxRequest(url, parameters = {}, method = 'GET', id, callback, errorCallback) {
    abortAjaxRequest(id)
    abortControllers[id] = new AbortController()

    const methodHandler = () => {
        const handlers = {
            GET: () => {
                const queryString = createQueryString(parameters)
                if (queryString) {
                    url += (url.includes('?') ? '&' : '?') + queryString
                }
                return {}
            },
            POST: () => {
                const formBody = []
                for (let property in parameters) {
                    let encodedKey = encodeURIComponent(property)
                    let encodedValue = encodeURIComponent(parameters[property])
                    formBody.push(`${encodedKey}=${encodedValue}`)
                }
                return { body: formBody.join('&') }
            },
        }

        const handler = handlers[method.toUpperCase()]
        if (!handler) {
            throw new Error('Unsupported HTTP method: ' + method)
        }
        return handler
    }

    const handler = methodHandler()

    const fetchOptions = {
        method: method.toUpperCase(),
        signal: abortControllers[id].signal,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        ...handler(),
    }

    fetch(url, fetchOptions)
        .then((response) => response.json())
        .then(callback)
        .catch((error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error)
            }
        })
}

function createQueryString(params) {
    const query = []
    function encodeParam(key, value) {
        if (typeof value === 'object' && value !== null) {
            for (const subKey in value) {
                if (value[subKey] !== null) {
                    encodeParam(`${key}[${subKey}]`, value[subKey])
                }
            }
        } else {
            query.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        }
    }

    for (const key in params) {
        encodeParam(key, params[key])
    }

    return query.join('&')
}

export function abortAjaxRequest(id) {
    if (id in abortControllers) {
        abortControllers[id].abort()
    }
}
