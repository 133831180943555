<template>
    <input
        v-bind="$attrs"
        class="form-control"
        :value="dateToString(internalValue)"
        :placeholder="dateFormat.placeholder"
        :data-date-format="dateFormat.datepicker"
        data-date-z-index-offset="1050"
    />
</template>

<script>
import moment from 'moment'

export default {
    props: {
        modelValue: {
            type: [String, Object, null],
            required: true,
        },
        asObject: {
            type: Boolean,
            default: true,
        },
        focusOnBlur: {
            type: [String, null],
            default: null,
        },
    },
    emits: ['update:modelValue', 'input'],
    data() {
        return {
            dateFormat: window.vivisol.dateFormat,
        }
    },
    computed: {
        internalValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', this.formatValue(value))
            },
        },
    },
    mounted: function () {
        const datepicker = this.withDatepicker()
        let previousValue = datepicker.datepicker('getDate') ?? null
        const isChanged = function (givenDate) {
            if (givenDate === null) {
                return previousValue !== null
            } else {
                return previousValue === null || givenDate.getTime() !== previousValue.getTime()
            }
        }

        datepicker.on(
            'changeDate hide',
            function (event) {
                const newDate = event.date ?? null

                if (isChanged(newDate)) {
                    this.internalValue = newDate
                    this.$emit('input', this.formatValue(newDate))
                    previousValue = newDate
                }

                if (event.type === 'hide') {
                    // Force lose focus
                    this.$el.blur()
                }

                const focusElement = document.getElementById(this.focusOnBlur)
                if (focusElement !== null) {
                    focusElement.focus()
                }
            }.bind(this)
        )
    },
    methods: {
        dateToString: function (date) {
            if (date === null || date === undefined) {
                return ''
            }

            return moment(date).format(window.vivisol.dateFormat.js)
        },
        withDatepicker: function (...args) {
            return window.$(this.$el).datepicker(...args)
        },
        changeSetting: function (setting, value) {
            this.withDatepicker(`set${setting.charAt(0).toUpperCase()}${setting.slice(1)}`, value)
        },
        formatValue: function (value) {
            return this.asObject || value === null
                ? value
                : // DatePicker configured as string, use Y-m-d format
                  moment(value).format(moment.HTML5_FMT.DATE)
        },
    },
}
</script>