<template>
    <div>{{ item.patient.vivisolPatientId }}</div>
</template>

<script>
import Column from '@/mixins/Column'

export default {
    name: 'PatientVivisolPatientId',
    mixins: [Column],
}
</script>