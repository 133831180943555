export default {
    props: {
        imageUrl: String,
    },
    methods: {
        getProductImageUrl: function (product) {
            return this.imageUrl.replace('---product_code---', product.productCode)
        },
        openImageModal: function (event) {
            window.vivisol.modal.displayContentFromUrl('productImages', event.currentTarget.getAttribute('href'), null)
        },
    },
}
