<template>
    <label for="patient-listing-filter__vivisol-patient-id">
        <i class="far fa-address-card d-inline" />
        {{ trans['patient.listing.vivisolPatientId'] }}
    </label>
    <div class="input-container bg-white border border-light">
        <input
            id="patient-listing-filter__vivisol-patient-id"
            v-model="filterValue"
            class="form-control"
            :placeholder="trans['patient.listing.vivisolPatientId']"
            @input="handleChange"
        />
    </div>
</template>

<script>
import FiltersMixin from '@/mixins/FiltersMixin'

export default {
    mixins: [FiltersMixin],
}
</script>