<template>
    <label for="patient-listing-filter__birth-date">
        <i class="far fa-calendar-alt d-inline" />
        {{ trans['patient.listing.birthDate'] }}
    </label>
    <div class="input-container bg-white border border-light">
        <vivisol-date-picker
            v-model="filterValue"
            :as-object="false"
            focus-on-blur="patientListingFilterSubmitButton"
            data-date-autoclose="true"
            data-date-start-date="-150y"
            data-date-end-date="0d"
            data-date-start-view="month"
            data-date-default-view-date="1y"
            data-date-assume-nearby-year="-1"
            data-date-orientation="bottom"
            @input="handleChange"
        />
    </div>
</template>

<script>
import FiltersMixin from '@/mixins/FiltersMixin'
import VivisolDatePicker from '@/components/common/VivisolDatePicker.vue'

export default {
    components: {
        VivisolDatePicker,
    },
    mixins: [FiltersMixin],
}
</script>
