<template>
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="format()" />
    <!--eslint-enable-->
</template>

<script>
import Column from '@/mixins/Column'
import TherapyColumn from '@/mixins/TherapyColumn'

export default {
    name: 'TherapyInstitutionName',
    mixins: [Column, TherapyColumn],
    methods: {
        format: function () {
            var htmlEscape = function (value) {
                const element = document.createElement('div')
                element.textContent = value
                return element.innerHTML
            }
            var therapies = this.getPatient()

            var institution, shortName, name
            var names = []
            var ids = []

            for (var i = 0; i < therapies.length; i++) {
                institution = therapies[i].institution
                if (!institution) {
                    continue
                }

                // Only include once
                if (ids.indexOf(institution.id) >= 0) {
                    continue
                }
                ids.push(institution.id)

                name = htmlEscape(institution.name)
                shortName = htmlEscape(institution.shortName)
                if (typeof shortName === 'string' && shortName.length > 0) {
                    name = '<span title="' + name + '">' + shortName + '</span>'
                }
                names.push(name)
            }

            return names.join(', ')
        },
    },
}
</script>