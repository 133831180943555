<template>
    <div id="product-limit-package-search-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ trans['productLimits.packages.search'] }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="table product-orderable table--header">
                        <div class="orderable-products-search">
                            <div class="form-group">
                                <div class="input-group d-flex align-items-center">
                                    <input
                                        v-model="search.query"
                                        type="text"
                                        :placeholder="trans['productLimits.packages.search.query']"
                                        class="border-light form-control"
                                        @keydown.enter.prevent="searchProductLimitPackagesByParameters"
                                    />
                                    <div class="filter">
                                        <label>{{ trans['productLimits.packages.search.filter'] }}</label>
                                        <select v-if="institutions" v-model="search.institution" class="border-light form-control mx-xl-15">
                                            <option :value="0">
                                                {{ trans['productLimits.packages.search.institution.none'] }}
                                            </option>
                                            <template v-for="institution in institutions">
                                                <option v-if="institution.name" :key="institution.id" :value="institution.id">
                                                    {{ institution.name }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <button type="button" class="btn-primary btn-lg btn" @click="searchProductLimitPackagesByParameters">
                                        {{ trans['productLimits.packages.search.submit'] }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="productLimitPackages.length" class="orderable-products-table max-vh-50">
                        <div class="products-table">
                            <div class="row products-table-header d-none d-xl-flex">
                                <div class="col-12 col-xl-2">
                                    <label>{{ trans['productLimits.packages.name'] }}</label>
                                </div>
                                <div class="col-12 col-xl-4">
                                    <label>{{ trans['productLimits.packages.description'] }}</label>
                                </div>
                                <div class="col-12 col-xl-2">
                                    <label>{{ trans['productLimits.packages.productCount'] }}</label>
                                </div>
                                <div class="col-12 col-xl-2">
                                    <label>{{ trans['productLimits.packages.institution'] }}</label>
                                </div>
                                <div class="col-12 col-xl-2">
                                    <label>{{ trans['productLimits.packages.add'] }}</label>
                                </div>
                            </div>
                            <div v-for="productLimitPackage in productLimitPackages" :key="productLimitPackage.id" class="row products-table-row">
                                <div class="col-12 col-xl-2">
                                    <label>{{ trans['productLimits.packages.name'] }}</label>
                                    <span>{{ productLimitPackage.name }}</span>
                                </div>
                                <div class="col-12 col-xl-4">
                                    <label>{{ trans['productLimits.packages.description'] }}</label>
                                    <span>{{ productLimitPackage.description }}</span>
                                </div>
                                <div class="col-12 col-xl-2">
                                    <label>{{ trans['productLimits.packages.productCount'] }}</label>
                                    <span>{{ productLimitPackage.productCount }}</span>
                                </div>
                                <div class="col-12 col-xl-2">
                                    <label>{{ trans['productLimits.packages.institution'] }}</label>
                                    <!-- eslint-disable vue/no-v-html -->
                                    <span v-html="getInstititionName(productLimitPackage.institution)" />
                                    <!-- eslint-enable -->
                                </div>
                                <div class="col-12 col-xl-2 action">
                                    <button
                                        type="button"
                                        class="btn btn-success float-right"
                                        :aria-label="trans['productLimits.package.action.add']"
                                        @click="addProductLimitPackage(productLimitPackage)"
                                    >
                                        <i class="fas fa-plus" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        <i v-if="search.searching" class="fa fa-2x fa-spinner fa-spin text-center mt-2" />
                        <span v-else-if="search.searched">{{ trans['productLimits.packages.noResults'] }}</span>
                        <span v-else>{{ trans['productLimits.packages.instructions'] }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        {{ trans['productLimits.packages.close'] }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ajaxRequest } from '@/services/ajax'
import Translations from '@/mixins/Translations'
import StringUtility from '@/mixins/StringUtility'

export default {
    name: 'ProductLimitsPackageSearch',
    mixins: [Translations, StringUtility],
    props: {
        searchUrl: { type: String, required: true },
        packageProductsUrl: { type: String, required: true },
        institutions: { type: Array, default: () => [] },
    },
    emits: ['add-product'],
    data() {
        return {
            search: {
                query: '',
                institution: 0,
                searching: false,
                searched: false,
            },
            productLimitPackages: [],
        }
    },
    methods: {
        searchProductLimitPackagesByParameters() {
            this.productLimitPackages = []
            this.search.searching = true

            ajaxRequest(
                this.searchUrl,
                {
                    query: this.search.query,
                    institution: this.search.institution,
                },
                'POST',
                'productLimitPackageSearch',
                function (results) {
                    this.productLimitPackages = results.productLimitPackages
                    this.search.searching = false
                    this.search.searched = true
                }.bind(this)
            )
        },
        addProductLimitPackage(productLimitPackage) {
            ajaxRequest(
                this.packageProductsUrl,
                {
                    packageId: productLimitPackage.id,
                },
                'POST',
                'productLimitPackageProducts' + productLimitPackage.id,
                function (results) {
                    if (!Array.isArray(results.packageProducts)) {
                        console.error('packageProducts is expected to be an array')
                        return
                    }

                    // Add each product by emitting the parent addProductLimit method
                    // Loop backwards to add products in the same order as in the package
                    for (var i = results.packageProducts.length - 1; i >= 0; i--) {
                        this.$emit('add-product', results.packageProducts[i])
                    }
                }.bind(this)
            )
        },
        getInstititionName(institution) {
            if (institution === null) {
                return ''
            }

            var name = this.escapeHtml(institution.name)
            if (institution.shortName !== null && institution.shortName !== '') {
                name = '<span title="' + name + '">' + this.escapeHtml(institution.shortName) + '</span>'
            }

            return name
        },
    },
}
</script>
