<template>
    <div>
        {{ trans[item.patient.accessToPortal ? 'accessToPortalYes' : 'accessToPortalNo'] }}
    </div>
</template>

<script>
import Column from '@/mixins/Column'

export default {
    name: 'PatientAccessToPortal',
    mixins: [Column],
}
</script>