<script>
import Translations from '@/mixins/Translations'
import ProductLimitsEditor from '@/components/ProductLimitsEditor'
import ProductLimitsSearch from '@/components/ProductLimitsSearch'
import ProductLimitsPackageSearch from '@/components/ProductLimitsPackageSearch'

export default {
    name: 'ProductLimits',
    delimiters: ['${', '}'],
    components: {
        ProductLimitsEditor,
        ProductLimitsSearch,
        ProductLimitsPackageSearch,
    },
    mixins: [Translations],
    props: {
        limits: {
            type: Array,
            default: () => [],
        },
        imageUrl: { type: String, required: true },
        initialProductLimitPeriod: {
            type: Object,
            default() {
                return {
                    translationLabel: 'product-limit-period.year',
                    yearToPeriodDivisionFactor: 1,
                }
            },
        },
        productLimitPeriodPerInstitution: {
            type: Object,
            required: true,
        },
        institutionSelectDomId: { type: String, default: null },
    },
    data() {
        return {
            search: {
                query: '',
                category: 0,
                searched: false,
            },
            productLimits: this.limits,
            productLimitPeriod: this.initialProductLimitPeriod,
        }
    },
    mounted() {
        this.initInstitutionSpecificSettings()
    },
    methods: {
        addProductLimit({ product, dividePeriod }) {
            this.productLimits.unshift(this.createProductLimit({ product, dividePeriod }))
        },
        removeProductLimit(index) {
            if (confirm(this.trans['productLimits.removeConfirmationText'])) {
                this.productLimits.splice(index, 1)
            }
        },
        createProductLimit({ product, dividePeriod }) {
            let numberOfProducts = null
            if (product.defaultProductLimit !== null && product.defaultProductLimit !== undefined) {
                numberOfProducts = dividePeriod ? Math.ceil(product.defaultProductLimit / this.productLimitPeriod.yearToPeriodDivisionFactor) : product.defaultProductLimit
            }

            return {
                product: product,
                numberOfProducts: numberOfProducts,
                period: 'year',
                directOrder: 0,
                alreadyOrdered: null,
            }
        },
        removeDuplicates() {
            var knownProductIds = []
            this.productLimits = this.productLimits.filter(function (productLimit) {
                if (knownProductIds.indexOf(productLimit.product.id) == -1) {
                    knownProductIds.push(productLimit.product.id)
                    return true
                }

                return false
            })
        },
        continueIfConfirmed(message, event) {
            if (!confirm(message)) {
                event.preventDefault()
            }
        },
        initInstitutionSpecificSettings() {
            if (!this.institutionSelectDomId) {
                return
            }

            let institutionSelect = document.getElementById(this.institutionSelectDomId)
            if (!institutionSelect) {
                console.error('DOM Element #' + this.institutionSelectDomId + ' not found')
                return
            }

            let eventHandler = () => {
                if (!institutionSelect.value) {
                    // No institution selected
                    return
                }
                this.productLimitPeriod = this.productLimitPeriodPerInstitution[institutionSelect.value]
            }
            institutionSelect.addEventListener('change', eventHandler)
            eventHandler()
        },
    },
}
</script>
