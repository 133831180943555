<template>
    <div id="product-search-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ trans['Product search'] }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="table product-orderable table--header">
                        <div class="orderable-products-search">
                            <div class="form-group">
                                <div class="input-group d-flex align-items-center">
                                    <input
                                        v-model="search.query"
                                        type="text"
                                        :placeholder="trans['Articlecode/Description']"
                                        class="border-light form-control"
                                        @keydown.enter.prevent="searchProductsByParameters"
                                    />
                                    <div v-if="labelColumn" class="filter">
                                        <label>{{ trans['productLimits.productLabel.filter'] }}</label>
                                        <select v-if="productLabels" v-model="search.productLabel" class="border-light form-control mx-xl-15">
                                            <option :value="0">
                                                {{ trans['productLimits.productLabel.all'] }}
                                            </option>
                                            <option v-for="(productLabelId, productLabelLabel) in productLabels" :key="productLabelId" :value="productLabelId">
                                                {{ productLabelLabel }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="filter">
                                        <label>{{ trans['productLimits.search.filter'] }}</label>
                                        <select v-if="categories" v-model="search.category" class="border-light form-control mx-xl-15">
                                            <option :value="0">
                                                {{ trans['productLimits.allCategories'] }}
                                            </option>
                                            <template v-for="category in categories">
                                                <option v-if="category.name" :key="category.id" :value="category.id">
                                                    {{ category.parentName }} /
                                                    {{ category.name }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <button type="button" class="btn-primary btn-lg float-right btn" @click="searchProductsByParameters">
                                        {{ trans['Search'] }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="products.length" class="orderable-products-table max-vh-50">
                        <div class="products-table">
                            <div class="row products-table-header d-none d-xl-flex flex-xl-nowrap">
                                <div class="col-12 col-xl-2" />
                                <div class="col-12 col-xl-1">
                                    <label>{{ trans['Articlecode'] }}</label>
                                </div>
                                <div class="col-12 col-xl-3">
                                    <label>{{ trans['Description'] }}</label>
                                </div>
                                <div class="col-12 col-xl">
                                    <label>{{ trans['Amount per package'] }}</label>
                                </div>
                                <div class="col-12 col-xl-2">
                                    <label>{{ trans['Category'] }}</label>
                                </div>
                                <div v-if="labelColumn" class="col-12 col-xl-2">
                                    <label>{{ trans['productLimits.productLabel'] }}</label>
                                </div>
                                <div class="col-12 col-xl-1">
                                    <label>{{ trans['Add product'] }}</label>
                                </div>
                            </div>
                            <div v-for="product in products" :key="product.id" class="row products-table-row">
                                <div class="col-12 col-xl-2">
                                    <a v-if="product.image" :href="getProductImageUrl(product)" class="image-container" @click.prevent="openImageModal">
                                        <img v-if="product.image" :src="product.image" />
                                    </a>
                                </div>
                                <div class="col-12 col-xl-1">
                                    <label>{{ trans['Articlecode'] }}</label>
                                    <span>{{ product.productCode }}</span>
                                </div>
                                <div class="col-12 col-xl-3">
                                    <label>{{ trans['Description'] }}</label>
                                    <span>{{ product.title }}</span>
                                </div>
                                <div class="col-12 col-xl">
                                    <label>{{ trans['Amount per package'] }}</label>
                                    <span>{{ product.unitsString }}</span>
                                </div>
                                <div class="col-12 col-xl-2">
                                    <label>{{ trans['Category'] }}</label>
                                    <span>
                                        <template v-if="product.category">{{ product.category.name }}</template>
                                    </span>
                                </div>
                                <div v-if="labelColumn" class="col-12 col-xl-2">
                                    <label>{{ trans['productLimits.productLabel'] }}</label>
                                    <span>
                                        {{ product.productLabels.join(', ') }}
                                    </span>
                                </div>
                                <div class="col-12 col-xl-1 action">
                                    <button type="button" class="btn btn-success float-right" @click="$emit('add-product', product)">
                                        <i class="fas fa-plus" />
                                        <span class="d-none">
                                            {{ trans['Add'] }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="search.searched" class="text-center">
                        {{ trans['productLimits.noResults'] }}
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        {{ trans['productLimits.close'] }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ajaxRequest } from '@/services/ajax'
import ProductImages from '@/mixins/ProductImages'
import Translations from '@/mixins/Translations'

export default {
    name: 'ProductLimitsSearch',
    mixins: [ProductImages, Translations],
    props: {
        searchUrl: { type: String, required: true },
        productLabels: { type: Array, default: () => [] },
        categories: { type: Array, default: () => [] },
        labelColumn: Boolean,
    },
    emits: ['add-product'],
    data() {
        return {
            search: {
                query: '',
                productLabel: 0,
                category: 0,
                searched: false,
            },
            products: [],
        }
    },
    methods: {
        searchProductsByParameters() {
            const { query, productLabel, category } = this.search
            ajaxRequest(
                this.searchUrl,
                {
                    query,
                    category,
                    ...(this.labelColumn && { productLabel }),
                },
                'POST',
                'productSearch',
                function (results) {
                    this.products = results.products
                    this.search.searched = true
                }.bind(this)
            )
        },
    },
}
</script>
