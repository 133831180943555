<template>
    <li class="dropdown-item py-2">
        <a :class="'icon ' + cssClass" :href="renderUrl()">{{ label }}</a>
    </li>
</template>

<script>
export default {
    name: 'ActionComponent',
    props: {
        cssClass: { type: String, default: '' },
        urlTemplate: { type: String, required: true },
        label: { type: String, required: true },
        patientId: { type: Number, required: true },
    },
    methods: {
        renderUrl: function () {
            return this.urlTemplate.replace(/---patient_id---/g, this.patientId)
        },
    },
}
</script>