import Translations from '@/mixins/Translations'

export default {
    data() {
        return {
            filterValue: null,
        }
    },
    emits: ['filtersChanged'],
    mixins: [Translations],
    props: {
        filterKey: {
            type: String,
            required: true,
        },
    },
    methods: {
        handleChange() {
            this.getSelectedFilterValue().forEach(({ key, value }) => this.$emit('filtersChanged', { key, value }))
        },
        getSelectedFilterValue() {
            return [
                {
                    key: this.filterKey,
                    value: this.filterValue,
                },
            ]
        },
    },
}
