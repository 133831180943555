import { createApp } from 'vue'
import components from './components/index'

// Add Vue instance to every element with the 'vue' attribute
document.querySelectorAll('[vue]').forEach((el) => {
    if (el.getAttribute('vue') === '') {
        return
    }

    const componentName = el.getAttribute('vue')
    const Component = components[componentName]
    if (!Component) {
        console.error(`Component ${componentName} not registered in /components/index.js`)
        return
    }

    const props = {}

    // Parse props from attributes
    Array.from(el.attributes).forEach((attr) => {
        if (attr.name.startsWith(':')) {
            props[attr.name.slice(1)] = JSON.parse(attr.value)
        } else if (attr.name !== 'vue') {
            props[attr.name] = attr.value
        }
    })

    // Creating the Vue app with a dynamic template and components
    const app = createApp(Component, props)

    app.mount(el)
})
