<template>
    <div>{{ format() }}</div>
</template>

<script>
import Column from '@/mixins/Column'

export default {
    name: 'PatientBirthDate',
    mixins: [Column],
    methods: {
        format: function () {
            return window.vivisol.formatDate(this.item.patient.birthDate)
        },
    },
}
</script>