export default {
    inject: {
        providedTranslations: {
            from: 'translations',
            default: () => [],
        },
    },
    props: {
        translations: Object,
        translationKeyPrefix: { type: String, default: null },
    },
    computed: {
        trans() {
            if (typeof this.translations === 'string') {
                return JSON.parse(this.translations)
            }

            if (this.translations) {
                return this.translations
            }
            return this.providedTranslations
        },
    },
    methods: {
        translateForComponent(prefix, key) {
            const value = this.trans[prefix + key]
            if (value !== undefined) {
                return this.trans[prefix + key]
            }
            console.warn('missing translation key', prefix + key)
        },
        transBool(value) {
            if (value == true) {
                return this.trans['yes'] ?? "missing translation for 'yes'"
            }

            return this.trans['no'] ?? "missing translation for 'no'"
        },
    },
    provide() {
        return {
            translations: this.trans,
        }
    },
}
