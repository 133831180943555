<template>
    <div>{{ format() }}</div>
</template>

<script>
import Column from '@/mixins/Column'

export default {
    name: 'PatientName',
    mixins: [Column],
    props: {
        lastNameFirst: Boolean,
    },
    methods: {
        format: function () {
            const patient = this.item.patient
            if (this.lastNameFirst) {
                return window.vivisol.string.force(patient.lastName) + window.vivisol.string.prepend(patient.initials, ', ') + vivisol.string.prepend(patient.lastNamePrefix, ' ')
            } else {
                return window.vivisol.string.force(patient.initials) + window.vivisol.string.prepend(patient.lastNamePrefix, ' ') + vivisol.string.prepend(patient.lastName, ' ')
            }
        },
    },
}
</script>