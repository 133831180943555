<template>
    <label>
        <i class="far fa-calendar-alt d-inline" />
        {{ label }}
    </label>
    <div class="input-container">
        <div class="row m-0">
            <div class="col-2 col-xl-4 p-0 d-flex flex-column justify-content-center border-xl-left border-light">
                <div class="mr-2 text-right">{{ dateFromLabel }}:</div>
            </div>
            <div class="col-10 col-xl-8 input-container combi-field combi-field-first bg-white border border-bottom-0 border-light">
                <vivisol-date-picker
                    v-bind="$attrs"
                    ref="fromDate"
                    v-model="dateFromInternal"
                    class="half-height"
                    data-date-autoclose="true"
                    data-date-start-date="-150y"
                    :data-date-end-date="endDate"
                    data-date-start-view="month"
                    data-date-default-view-date="1y"
                    data-date-assume-nearby-year="-1"
                    data-date-orientation="bottom"
                    :focus-on-blur="`${componentId}_to`"
                    @input="onFromDateChange"
                />
            </div>
        </div>

        <div class="row m-0">
            <div class="col-2 col-xl-4 p-0 d-flex flex-column justify-content-center border-xl-left border-light">
                <div class="mr-2 text-right">{{ dateToLabel }}:</div>
            </div>
            <div class="col-10 col-xl-8 input-container combi-field combi-field-second bg-white border border-light">
                <vivisol-date-picker
                    v-bind="$attrs"
                    :id="`${componentId}_to`"
                    ref="toDate"
                    v-model="dateToInternal"
                    class="half-height"
                    data-date-autoclose="true"
                    data-date-start-date="-150y"
                    :data-date-end-date="endDate"
                    data-date-start-view="month"
                    data-date-default-view-date="1y"
                    data-date-assume-nearby-year="-1"
                    data-date-orientation="bottom"
                    focus-on-blur="patientListingFilterSubmitButton"
                    @input="onToDateChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import VivisolDatePicker from './VivisolDatePicker.vue'
import moment from 'moment/moment'

export default {
    components: {
        VivisolDatePicker,
    },
    props: {
        dateFrom: {
            type: [String, Object, null],
            required: true,
        },
        dateTo: {
            type: [String, Object, null],
            required: true,
        },
        label: { type: String, required: true },
        dateFromLabel: { type: String, required: true },
        dateToLabel: { type: String, required: true },
        asObject: {
            type: Boolean,
            default: true,
        },
        endDate: {
            type: String,
            default: '0d',
        },
    },
    emits: ['update:dateFrom', 'update:dateTo', 'input'],
    data: function () {
        return {
            dateFromInternal: this.dateFrom,
            dateToInternal: this.dateTo,
            componentId: `date_range_picker_${parseInt(Math.random() * 10000000)}`,
        }
    },
    methods: {
        onFromDateChange(dateFrom) {
            const toField = this.$refs.toDate
            toField.changeSetting(
                'startDate',
                // Revert to initial setting when setting to null
                dateFrom === null ? toField.$attrs['data-date-start-date'] : new Date(dateFrom)
            )

            this.$emit('update:dateFrom', this.formatValue(dateFrom))
            this.$emit('input', {
                dateFrom: this.formatValue(dateFrom),
                dateTo: this.formatValue(this.dateToInternal),
            })
        },
        onToDateChange(dateTo) {
            const fromField = this.$refs.fromDate
            fromField.changeSetting(
                'endDate',
                // Revert to initial setting when setting to null
                dateTo === null ? fromField.$attrs['data-date-end-date'] : new Date(dateTo)
            )

            this.$emit('update:dateTo', this.formatValue(dateTo))
            this.$emit('input', {
                dateFrom: this.formatValue(this.dateFromInternal),
                dateTo: this.formatValue(dateTo),
            })
        },
        formatValue: function (value) {
            return this.asObject || value === null
                ? value
                : // DatePicker configured as string, use Y-m-d format
                  moment(value).format(moment.HTML5_FMT.DATE)
        },
    },
}
</script>