export default {
    name: 'TherapyColumnMixin',
    props: {
        patientTherapyField: {
            type: String,
            default: '',
        },
    },
    methods: {
        getPatient: function () {
            return this.item.patient[this.patientTherapyField]
        },
    },
}
