import Translations from './Translations'

export default {
    name: 'ColumnMixin',
    mixins: [Translations],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
}
