<template>
    <div>{{ format() }}</div>
</template>

<script>
import Column from '@/mixins/Column'
import TherapyColumn from '@/mixins/TherapyColumn'

export default {
    name: 'TherapySatmonInstallationStatus',
    mixins: [Column, TherapyColumn],
    methods: {
        format: function () {
            return this.getPatient()
                .map((therapy) => (therapy.installationInfo ? therapy.installationInfo.statusTranslated : this.trans['satmon.therapy-status-from-installation-status.unknown']))
                .filter((v) => v)
                .join(', ')
        },
    },
}
</script>