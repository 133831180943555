<template>
    <div>{{ item.patient.email }}</div>
</template>

<script>
import Column from '@/mixins/Column'

export default {
    name: 'PatientEmail',
    mixins: [Column],
}
</script>