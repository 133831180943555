<script>
/**
 * The template for this component is generated in Twig and not included in this file.
 * See the `templates/portal/patient-listing/table.html.twig` file for the Bootstrap table, which uses props and state from this component.
 */
import { elementContainsSelectedText } from '@/services/dom'
import Translations from '@/mixins/Translations'
import AsyncTable from '@/components/AsyncTable.vue'

import ColumnPatientName from './Column/Patient/Name'
import ColumnPatientBirthDate from './Column/Patient/BirthDate'
import ColumnPatientVivisolPatientId from './Column/Patient/VivisolPatientId'
import ColumnTherapyInstitutionPatientId from './Column/Therapy/InstitutionPatientId'
import ColumnTherapySatmonOrderNumber from './Column/Therapy/Satmon/OrderNumber'
import ColumnTherapySatmonCustomerRequestCode from './Column/Therapy/Satmon/CustomerRequestCode'
import ColumnTherapyRequestedDate from './Column/Therapy/RequestedDate'
import ColumnTherapySatmonActualOrPlannedInstallationDate from './Column/Therapy/Satmon/ActualOrPlannedInstallationDate'
import ColumnTherapySatmonVacationMonitor from './Column/Therapy/Satmon/VacationMonitor'
import ColumnTherapySatmonInstallationStatus from './Column/Therapy/Satmon/InstallationStatus'
import ColumnTherapyInstitutionName from './Column/Therapy/InstitutionName'
import ColumnActions from './Column/Actions'
import ColumnPatientAccessToPortal from './Column/Patient/AccessToPortal'
import ColumnPatientEmail from './Column/Patient/Email'

import FilterApplySearch from './Filter/ApplySearch'
import FilterVivisolOrInstitutionPatientId from './Filter/VivisolOrInstitutionPatientId'
import FilterBirthDate from './Filter/BirthDate'
import FilterCustomerRequestCode from './Filter/CustomerRequestCode'
import FilterLastname from './Filter/Lastname'
import FilterOrderNumber from './Filter/OrderNumber'
import FilterRequestDate from './Filter/RequestDate'
import FilterInstallationDate from './Filter/InstallationDate'
import FilterPrescriberName from './Filter/PrescriberName'
import FilterHospitalName from './Filter/HospitalName'
import FilterPrescriberDropdown from './Filter/PrescriberDropdown'
import FilterVivisolPatientId from './Filter/VivisolPatientId'
import FilterAccessToPortal from './Filter/AccessToPortal'
import FilterInstitutionTeam from './Filter/InstitutionTeam'

export default {
    name: 'PatientListing', // Enable vue2 support for bootstrap-vue
    components: {
        AsyncTable,

        ColumnPatientName,
        ColumnPatientBirthDate,
        ColumnPatientVivisolPatientId,
        ColumnTherapyInstitutionPatientId,
        ColumnTherapySatmonOrderNumber,
        ColumnTherapySatmonCustomerRequestCode,
        ColumnTherapyRequestedDate,
        ColumnTherapySatmonActualOrPlannedInstallationDate,
        ColumnTherapySatmonVacationMonitor,
        ColumnTherapySatmonInstallationStatus,
        ColumnTherapyInstitutionName,
        ColumnActions,
        ColumnPatientAccessToPortal,
        ColumnPatientEmail,

        FilterApplySearch,
        FilterVivisolOrInstitutionPatientId,
        FilterBirthDate,
        FilterCustomerRequestCode,
        FilterLastname,
        FilterOrderNumber,
        FilterRequestDate,
        FilterInstallationDate,
        FilterPrescriberName,
        FilterHospitalName,
        FilterPrescriberDropdown,
        FilterVivisolPatientId,
        FilterAccessToPortal,
        FilterInstitutionTeam,
    },
    mixins: [Translations],
    props: {
        detailUrlFormat: { type: String, required: true },
    },
    data() {
        return {
            search: {},
        }
    },
    methods: {
        handleFilterChange: function ({ key, value }) {
            this.search[key] = value
        },
        applySearch: function () {
            this.$refs.patientTable.updateParameters({
                filter: JSON.parse(JSON.stringify(this.search)),
            })
        },
        handleRowClick: function (event, item) {
            if (elementContainsSelectedText(event.target)) {
                return
            }

            this.gotoPatientDetails(item)
        },
        gotoPatientDetails: function (item) {
            var url = this.detailUrlFormat

            if (url.indexOf('---patient_id---') !== -1) {
                url = url.replace('---patient_id---', item.patient.id)
            }

            window.location = url
        },
        isAnyfilterActive: function () {
            for (const filterValue of Object.values(this.search)) {
                if (filterValue !== null && filterValue !== '') {
                    return true
                }
            }

            return false
        },
    },
}
</script>
