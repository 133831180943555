<template>
    <label for="patient-listing-filter__prescriber-name-text">
        {{ trans['patient.listing.prescriber-name-text.label'] }}
    </label>
    <div class="input-container bg-white border border-light">
        <input
            id="patient-listing-filter__prescriber-name-text"
            v-model="filterValue"
            class="form-control"
            :placeholder="trans['patient.listing.prescriber-name-text.placeholder']"
            @input="handleChange"
        />
    </div>
</template>

<script>
import FiltersMixin from '@/mixins/FiltersMixin'

export default {
    mixins: [FiltersMixin],
}
</script>