<template>
    <!--
    Template is built using the prototype widget below. When updating the formtype, uncomment the widget
    in form_fields.html.twig and update the template below to the new specifications
    {{ form_widget(form.formProductLimits.vars.prototype) }}
    -->
    <div class="table product-limit">
        <div class="row table--header d-none d-xl-block">
            <div class="offset-xl-2 col-xl-9">
                <div :class="{ row: !dualLine }">
                    <div
                        class="row"
                        :class="{
                            'col-xl-8': !dualLine && periodColumn,
                            'col-xl-10': !dualLine && !periodColumn,
                        }"
                    >
                        <div class="col col-xl-2 form-group mb-0">
                            <label class="col-form-label minimal">{{ trans['productLimits.productCode'] }}</label>
                        </div>
                        <div class="col col-xl form-group mb-0">
                            <label class="col-form-label minimal">{{ trans['productLimits.name'] }}</label>
                        </div>
                        <div v-if="labelColumn" class="col col-xl-3 form-group mb-0">
                            <label class="col-form-label minimal">{{ trans['productLimits.productLabel'] }}</label>
                        </div>
                        <div v-if="categoryColumn" class="col col-xl form-group mb-0">
                            <label class="col-form-label minimal">{{ trans['productLimits.category'] }}</label>
                        </div>
                        <div class="col col-xl-2 form-group mb-0">
                            <label class="col-form-label minimal">{{ trans['productLimits.unitsString'] }}</label>
                        </div>
                    </div>
                    <div class="row" :class="{ 'col-xl': !dualLine }">
                        <div v-if="periodColumn" class="col-xl form-group mb-0">
                            <label class="col-form-label">{{ trans['Period'] }}</label>
                        </div>
                        <div class="col-xl form-group mb-0">
                            <label class="col-form-label label-with-tooltip">
                                <span>{{ trans['productLimits.numberOfProducts__per__' + productLimitPeriod.translationLabel] }}</span>
                                <a href="#" data-toggle="tooltip" :title="trans['For an unlimited number of products: use the value 0']">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true" />
                                </a>
                            </label>
                        </div>
                        <div v-if="includeDirectOrder" class="col-xl-4 form-group mb-0">
                            <label class="col-form-label label-with-tooltip">
                                <span>{{ trans['Direct order'] }}</span>
                                <a href="#" data-toggle="tooltip" :title="trans['Number of products you want to order on behalf of the patient']">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true" />
                                </a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="(productLimit, index) in productLimits" :key="index" class="product-limit-item row" :class="{ inactive: !productLimit.product.isActive }">
            <div class="product-limit-image col-12 col-xl-2">
                <a v-if="productLimit.product.image" :href="getProductImageUrl(productLimit.product)" @click.prevent="openImageModal">
                    <img :src="productLimit.product.image" />
                </a>
            </div>

            <div :id="formId + '_' + fieldName + index" class="col-12 col-xl-9 my-3 my-lg-0">
                <div :class="{ row: !dualLine }">
                    <div
                        class="product-limit-info row d-flex"
                        :class="{
                            'col-xl-8': !dualLine && periodColumn,
                            'col-xl-10': !dualLine && !periodColumn,
                        }"
                    >
                        <div class="product-limit-form col-12 col-xl-2">
                            <div class="row">
                                <label class="col-6 col-xl-12">
                                    {{ trans['productLimits.productCode'] }}
                                </label>
                                <div class="col-6 col-xl-12">
                                    <input
                                        :id="formId + '_' + fieldName + '_' + index + '_product_id'"
                                        v-model="productLimit.product.id"
                                        type="hidden"
                                        :name="formName + '[' + fieldName + '][' + index + '][product][id]'"
                                    />
                                    <span>{{ productLimit.product.productCode }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="product-limit-form col-12 col-xl">
                            <div class="row">
                                <label class="col-6 col-xl-12">
                                    {{ trans['productLimits.name'] }}
                                </label>
                                <div class="col-6 col-xl-12">
                                    <span>{{ productLimit.product.title }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="labelColumn" class="product-limit-form col-12 col-xl-3">
                            <div class="row">
                                <label class="col-6 col-xl-12">
                                    {{ trans['productLimits.productLabel'] }}
                                </label>
                                <div class="col-6 col-xl-12">
                                    <span>{{ productLimit.product.productLabels.join(', ') }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="categoryColumn" class="product-limit-form col-12 col-xl">
                            <div class="row">
                                <label class="col-6 col-xl-12">
                                    {{ trans['productLimits.category'] }}
                                </label>
                                <div class="col-6 col-xl-12">
                                    <span>{{ productLimit.product.category !== null ? productLimit.product.category.name : '' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="product-limit-form col-12 col-xl-2">
                            <div class="row">
                                <label class="col-6 col-xl-12">
                                    {{ trans['productLimits.unitsString'] }}
                                </label>
                                <div class="col-6 col-xl-12">
                                    <span>{{ productLimit.product.unitsString }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex" :class="{ 'col-xl': !dualLine }">
                        <div v-if="periodColumn" class="product-limit-form col-12 col-xl">
                            <div class="row">
                                <label class="col-6 col-xl-12 required" :for="formId + '_' + fieldName + '_' + index + '_period'">
                                    {{ trans['Period'] }}
                                </label>
                                <div class="col-6 col-xl-12">
                                    <select
                                        :id="formId + '_' + fieldName + '_' + index + '_period'"
                                        v-model="productLimit.period"
                                        :name="formName + '[' + fieldName + '][' + index + '][period]'"
                                        class="form-control"
                                    >
                                        <option value="year">
                                            {{ trans['productLimits.year'] }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="product-limit-form col-12 col-xl">
                            <div class="row">
                                <label class="col-6 col-xl-12 required label-with-tooltip" :for="formId + '_' + fieldName + '_' + index + '_numberOfProducts'">
                                    <span>{{ trans['productLimits.numberOfProducts__per__' + productLimitPeriod.translationLabel] }}</span>
                                    <a href="#" data-toggle="tooltip" :title="trans['For an unlimited number of products: use the value 0']">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true" />
                                    </a>
                                </label>
                                <div class="col-6 col-xl-12">
                                    <input
                                        :id="formId + '_' + fieldName + '_' + index + '_numberOfProducts'"
                                        v-model="productLimit.numberOfProducts"
                                        type="number"
                                        :name="formName + '[' + fieldName + '][' + index + '][numberOfProducts]'"
                                        required="required"
                                        :aria-describedby="formId + '_' + fieldName + '_' + index + '_numberOfProducts_help'"
                                        class="form-control"
                                        min="0"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="includeDirectOrder" class="product-limit-form col-12 col-xl-4">
                            <div class="row">
                                <label class="col-6 col-xl-12 required label-with-tooltip" :for="formId + '_' + fieldName + '_' + index + '_directOrder'">
                                    <span>{{ trans['Direct order'] }}</span>
                                    <a href="#" data-toggle="tooltip" :title="trans['Number of products you want to order on behalf of the patient']">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true" />
                                    </a>
                                </label>
                                <div class="col-6 col-xl-12">
                                    <input
                                        :id="formId + '_' + fieldName + '_' + index + '_directOrder'"
                                        v-model="productLimit.directOrder"
                                        type="number"
                                        :name="formName + '[' + fieldName + '][' + index + '][directOrder]'"
                                        required="required"
                                        :aria-describedby="formId + '_' + fieldName + '_' + index + '_directOrder_help'"
                                        class="form-control"
                                        min="0"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div :id="formId + '_' + fieldName + index" class="action-toolbar col-12 col-xl-1 justify-content-end">
                <button type="button" class="btn btn-danger" @click="$emit('remove-product-limit', index)">
                    <i class="fas fa-times d-none d-xl-block" />
                    <span class="d-xl-none">
                        {{ trans['Remove'] }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ProductImages from '@/mixins/ProductImages'
import Translations from '@/mixins/Translations'

export default {
    name: 'ProductLimitsEditor',
    mixins: [ProductImages, Translations],
    props: {
        formId: { type: String, required: true },
        formName: { type: String, required: true },
        fieldName: { type: String, required: true },
        productLimits: { type: Array, default: () => [] },
        includeDirectOrder: Boolean,
        labelColumn: Boolean,
        categoryColumn: Boolean,
        periodColumn: Boolean,
        productLimitPeriod: { type: Object, required: true },
    },
    emits: ['remove-product-limit'],
    computed: {
        dualLine() {
            // DirectOrder and/or labelColumn are too wide to have everything on one line. If both are not included,
            // then we can show it on one single line.
            return this.includeDirectOrder || this.labelColumn
        },
    },
}
</script>
