export const getSelection = () => (window.getSelection ? window.getSelection() : null)

export const elementContainsSelectedText = (element) => {
    if (!element || element.nodeType !== Node.ELEMENT_NODE) {
        // Not a (valid) element given
        return false
    }

    const selection = getSelection()
    if (!selection || selection.toString().length === 0) {
        // Nothing selected
        return false
    }

    return selection.containsNode && selection.containsNode(element, true)
}
